














































import Vue from 'vue';
import { mapState } from 'vuex';
import { RootState } from '@/store';

export default Vue.extend({
	name: 'info',
	computed: {
		...mapState({
			isAuthenticated: (state) => (state as RootState).auth.isAuthenticated
		})
	}
});
