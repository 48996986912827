









































































import Vue from 'vue';
import vTable from '@/components/table.vue';
import { RootState } from '@/store';
import { mapActions, mapGetters } from 'vuex';
import VForm from '@/types/vuetify';
import { FileType } from '@/types/sqlite';

interface FileListData {
	dialog: boolean;
	fileName: string | null;
	fileText: string | null;
	valid: boolean;
	editing: FileType | null;
}

export default Vue.extend({
	name: 'FileList',
	components: {
		vTable
	},
	data(): FileListData {
		return {
			dialog: false,
			fileName: null,
			fileText: null,
			valid: false,
			editing: null
		};
	},
	computed: {
		...mapGetters(['getSelectedFile', 'projectIsSaving']),
		files(): {
			headers: { [prop: string]: string }[];
			data: FileType[];
		} {
			return {
				headers: [
					{ value: 'name', text: 'Name' },
					{ value: 'actions', text: 'Actions' }
				],
				data: (this.$store
					.state as RootState).projectCurrent.sources.data.filter(
					(v) => v.status === 1
				)
			};
		}
	},
	methods: {
		...mapActions(['addFile', 'updateFile', 'deleteFile', 'updateMemoFile']),
		selectionChange({ item, value }: { item: FileType; value: boolean }) {
			// TODO: move this function to an action
			if (value) {
				this.$router.push({
					name: 'file',
					params: { fileId: String(item.id) }
				});
			} else {
				this.$router.push({ name: 'file-list' });
			}
		},
		openNewFile() {
			this.editing = null;
			if (this.$refs.form) (this.$refs.form as HTMLFormElement).reset();
			this.dialog = true;
		},
		openEditFile(item: FileType) {
			this.editing = item;
			this.fileName = this.editing.name;
			this.fileText = this.editing.file;
			this.dialog = true;
		},
		save() {
			const isFormValidated = (this.$refs.form as VForm).validate();
			if (!isFormValidated) return;
			if (this.editing) {
				const item = { ...this.editing, name: this.fileName };
				this.updateFile(item);
				this.dialog = false;
			} else {
				this.addFile({ name: this.fileName, file: this.fileText });
				this.dialog = false;
			}
		}
	}
});
